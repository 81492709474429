<template>
  <div class="flex flex-col gap-y-4">
    <h1 class="hidden lg:block font-EffraM text-xl text-black">
      Notifications
    </h1>
    <div class="flex lg:hidden flex-row bg-white py-3">
      <svg
        class="cursor-pointer"
        @click="openMobileNav"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#111"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 18l-6-6 6-6"
        />
      </svg>
      <div class="flex-1 flex justify-center font-EffraM text-center text-lg">
        Notifications
      </div>
    </div>

    <div class="flex flex-col gap-y-2 px-5 lg:px-0">
      <div class="w-full h-full flex items-center justify-center" v-if="loader">
        <loader />
      </div>
      <template v-if="!loader">
        <!-- <phone-verification></phone-verification> -->
        <appointment-type
          v-for="(notify, index) in list"
          :key="index"
          :type="1"
          :data="notify"
        ></appointment-type>
      </template>
    </div>
  </div>
</template>

<script>
const phoneVerification = () => import("./layouts/notifications/phone.vue");
const appointmentType = () => import("./layouts/notifications/appointment.vue");
const loader = () => import("@/views/global-components/loader");
import { EventBus } from "@/event-bus";

export default {
  components: { phoneVerification, appointmentType, loader },
  data() {
    return {
      list: [],
      loader: true
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    EventBus.$on("RELOAD_NOTIFICATIONS_PATIENT", this.getList);
  },
  methods: {
    openMobileNav() {
      EventBus.$emit("Change_Page_Number_My_Account_Patient", -1);
    },
    async getList() {
      this.loader = true;
      await this.$store.dispatch("user/GET_PATIENT_NOTIFICATIONS", {
        onData: ({ ok, data }) => {
          if (ok) {
            this.list = data;
            this.$nextTick(() => {
              this.loader = false;
            });
          }
        }
      });
    }
  }
};
</script>
